<template>
  <div class="voucher-cumluative-config">
    <b-modal
      ref="voucher-cumluative-modal"
      title="Cấu hình giá trị tích lũy voucher"
      hide-footer
    >
      <b-button
        class="mb-4 font-weight-bolder"
        @click="onClickToAdd"
        size="sm"
        variant="primary"
      >
        <i style="font-size: 1rem" class="flaticon2-add-1"></i>Thêm mới
      </b-button>
      <b-table bordered hover :fields="fields" :items="cumluativeValues">
        <template v-slot:cell(quantityFrom)="row">
          <BInputNumber
            size="sm"
            v-model="row.item.quantityFrom"
            placeholder="Nhập SL từ"
            class="text-right"
          />
        </template>
        <template v-slot:cell(quantityTo)="row">
          <BInputNumber
            size="sm"
            v-model="row.item.quantityTo"
            placeholder="Nhập sL đến"
            class="text-right"
          />
        </template>
        <template v-slot:cell(cumulativeValue)="row">
          <BInputNumber
            size="sm"
            v-model="row.item.cumulativeValue"
            placeholder="Nhập giá trị giảm"
            class="text-right"
          />
        </template>
        <template v-slot:cell(actions)="row">
          <v-icon
            small
            class="text-danger text-center"
            @click="deleteItemInArray(row.item.id)"
            v-b-tooltip
            title="Xóa"
            >mdi-delete</v-icon
          >
        </template>
      </b-table>
      <b-button
        style="fontweight: 600; width: 100px"
        variant="primary"
        size="sm"
        @click="onSave()"
        >Lưu</b-button
      >
      <b-button
        style="margin-left: 10px; font-weight: 600; width: 70px"
        variant="secondary"
        size="sm"
        @click="hideModal()"
        >Hủy</b-button
      >
    </b-modal>
  </div>
</template>
<script>
import { v4 } from 'uuid';
import BInputNumber from '@/view/base/bootstrap/BInputNumber.vue';
import { cloneDeep } from '@/utils/common';

export default {
  data() {
    return {
      cumluativeValues: [],
      fields: [
        {
          key: 'quantityFrom',
          label: 'SL từ',
          thStyle: { fontWeight: 600, color: '#181c32', width: '30%' },
          tdClass: 'text-right',
        },
        {
          key: 'quantityTo',
          label: 'SL đến',
          thStyle: { fontWeight: 600, color: '#181c32', width: '30%' },
          tdClass: 'text-right',
        },
        {
          key: 'cumulativeValue',
          label: 'Giá trị giảm',
          thStyle: { fontWeight: 600, color: '#181c32', width: '30%' },
          tdClass: 'text-right',
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
        },
      ],
    };
  },
  methods: {
    showModal(voucherCumluativeValues) {
      this.cumluativeValues = cloneDeep(voucherCumluativeValues);
      this.$refs['voucher-cumluative-modal'].show();
    },
    hideModal() {
      this.$refs['voucher-cumluative-modal'].hide();
    },
    onSave() {
      this.$emit('receive-cumulative', this.cumluativeValues);
      this.hideModal();
    },
    onClickToAdd() {
      this.cumluativeValues.push({
        id: v4(),
        quantityFrom: 0,
        quantityTo: 0,
        cumulativeValue: 0,
      });
    },
    deleteItemInArray(id) {
      for (var i = 0; i < this.cumluativeValues.length; i++) {
        if (this.cumluativeValues[i].id === id) {
          this.cumluativeValues.splice(i, 1);
        }
      }
      this.cumluativeValues.forEach((element, index) => {
        element.level = index + 1;
      });
    },
    onCallApiSuccess() {
      this.hideModal();
      this.$emit('on-submit-success');
    },
  },
  components: {
    BInputNumber,
  },
};
</script>